














































import './styles/MemberStatuses.scss';
import './styles/MemberStatusesAdaptive.scss';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { IMemberDocStatuses } from '@store/modules/project-team/interfaces/Interfaces';
import { ProjectTeamActions } from '@store/modules/project-team/Types';
import { IChangeMemberDocStatusesPostData } from '@store/modules/project-team/interfaces/ApiRequests';
import { IUserState } from '@store/modules/user/Interfaces';
import { IProject } from '@store/modules/project/Interfaces';

const NSUser = namespace('storeUser');
const NSProject = namespace('storeProject');
const NSProjectTeam = namespace('storeProjectTeam');
const NSDocuments = namespace('storeProjectDocuments');

@Component({
    name: 'MemberStatuses',

    components: {
        BaseSelect: ()  => import('@components/BaseSelect'),
        MemberRights: () => import('./MemberRights.vue'),
    },
})
export default class MemberStatuses extends Vue {
    @Prop({}) memberStatuses!: any;
    @Prop({}) memberUuid!: string;

    @NSUser.Getter('userData') userData!: IUserState;
    @NSProject.Getter('projectId') projectId!: number;
    @NSProject.Getter('projectData') projectData!: IProject;
    @NSDocuments.Getter('statuses') documentStatuses!: any;
    @NSProjectTeam.Action(ProjectTeamActions.A_CHANGE_MEMBER_DOC_STATUSES)
    changeMemberDocStatuses!: (payload: { params: IChangeMemberDocStatusesPostData, statuses: IMemberDocStatuses[] }) => Promise<void>

    memberDocumentStatuses: IMemberDocStatuses[] = [];

    mounted() {
        this.memberDocumentStatuses = this.defaultMemberDocumentStatuses;
    }

    get definedActiveStatus() {
        return this.memberStatuses.active ? 'Активен' : 'Не активен';
    }

    get definedLegalStatus() {
        return this.memberStatuses.legalStatus === 'physical' ? 'Физическое лицо' : 'Юридическое лицо';
    }

    get defaultMemberDocumentStatuses() {
        const memberSigningStatuses = this.memberStatuses.signingStatuses?.map(status => status.id);
        return memberSigningStatuses && this.documentStatuses?.length
            ? this.documentStatuses.filter(status => (memberSigningStatuses.includes(status.id)))
            : undefined;
    }

    get isCurrentUserAdmin() {
        if (this.userData && this.userData.userInfo) {
            const userById = this.projectData.members.filter(item => item.uuid === this.userData.userInfo.id);
            if (userById && userById[0] && userById[0].rights && userById[0].rights.length) {
                return userById[0].rights.filter(right => right.label === 'Администратор').length !== 0;
            }
        }
        return false;
    }

    @Watch('defaultMemberDocumentStatuses')
    watchDefaultMemberDocumentStatuses() {
        if (this.defaultMemberDocumentStatuses && !this.memberDocumentStatuses?.length) {
            this.memberDocumentStatuses = this.defaultMemberDocumentStatuses;
        }
    }

    addStatusesToUser(): void {
        this.changeMemberDocStatuses(
            { params: this.changeMemberDocStatusesPostData(), statuses: this.memberDocumentStatuses }
        );
    }

    changeMemberDocStatusesPostData(): IChangeMemberDocStatusesPostData {
        return {
            appId: this.projectId,
            userId: this.memberUuid,
            statusIds: this.memberDocumentStatuses.map(status => status.id),
        }
    }
}
